export default {
    bg: require('../assets/images/cook.kitchen.jpg'),
    sprites: [{
        image: require('../assets/images/cook.deer.png'),
        x: 25,
        y: 40,
        rot: 0,
        scale: .8,
    }, {
        image: require('../assets/images/cook.elephant.png'),
        x: 55,
        y: 55,
        rot: 0,
        scale: .8,
    }],
}